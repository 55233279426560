import Clipboard from "clipboard"
import axios from "axios"
import Vue from "vue"
import dayjs from "dayjs"
import ExcelJS from "exceljs"
import * as FileSaver from "file-saver"

export function isNull(value) {
  return value === null || value === undefined
}

export function isNotNull(value) {
  return value !== null && value !== undefined
}

export function isEmptyStr(str) {
  // return (str === undefined) || (!str) || (!/[^\s]/.test(str));
  return str === undefined || (!str && str !== 0 && str !== "0") || !/[^\s]/.test(str)
}

export const generateId = function () {
  return Math.floor(Math.random() * 100000 + Math.random() * 20000 + Math.random() * 5000)
}

export const deepClone = function (origin) {
  if (origin === undefined) {
    return undefined
  }

  return JSON.parse(JSON.stringify(origin))
}

export const overwriteObj = function (obj1, obj2) {
  /* 浅拷贝对象属性，obj2覆盖obj1 */
  // for (let prop in obj2) {
  //   if (obj2.hasOwnProperty(prop)) {
  //     obj1[prop] = obj2[prop]
  //   }
  // }

  Object.keys(obj2).forEach((prop) => {
    obj1[prop] = obj2[prop]
  })
}

export const addWindowResizeHandler = function (handler) {
  let oldHandler = window.onresize
  if (typeof window.onresize != "function") {
    window.onresize = handler
  } else {
    window.onresize = function () {
      oldHandler()
      handler()
    }
  }
}

// const createStyleSheet = function () {
//   let head = document.head || document.getElementsByTagName("head")[0]
//   let style = document.createElement("style")
//   style.type = "text/css"
//   head.appendChild(style)
//   return style.sheet
// }

export const insertCustomCssToHead = function (cssCode, formId = "") {
  let head = document.getElementsByTagName("head")[0]
  let oldStyle = document.getElementById("vform-custom-css")
  if (oldStyle) {
    head.removeChild(oldStyle) // 先清除后插入！！
  }
  if (formId) {
    oldStyle = document.getElementById("vform-custom-css" + "-" + formId)
    !!oldStyle && head.removeChild(oldStyle) // 先清除后插入！！
  }

  let newStyle = document.createElement("style")
  newStyle.type = "text/css"
  newStyle.rel = "stylesheet"
  newStyle.id = formId ? "vform-custom-css" + "-" + formId : "vform-custom-css"
  try {
    newStyle.appendChild(document.createTextNode(cssCode))
  } catch (ex) {
    newStyle.styleSheet.cssText = cssCode
  }

  head.appendChild(newStyle)
}

export const insertGlobalFunctionsToHtml = function (functionsCode, formId = "") {
  let bodyEle = document.getElementsByTagName("body")[0]
  let oldScriptEle = document.getElementById("v_form_global_functions")
  !!oldScriptEle && bodyEle.removeChild(oldScriptEle) // 先清除后插入！！
  if (formId) {
    oldScriptEle = document.getElementById("v_form_global_functions" + "-" + formId)
    !!oldScriptEle && bodyEle.removeChild(oldScriptEle) // 先清除后插入！！
  }

  let newScriptEle = document.createElement("script")
  newScriptEle.id = formId ? "v_form_global_functions" + "-" + formId : "v_form_global_functions"
  newScriptEle.type = "text/javascript"
  newScriptEle.innerHTML = functionsCode
  bodyEle.appendChild(newScriptEle)
}

export const optionExists = function (optionsObj, optionName) {
  if (!optionsObj) {
    return false
  }

  return Object.keys(optionsObj).indexOf(optionName) > -1
}

export const loadRemoteScript = function (srcPath, callback) {
  /* 加载远程js，加载成功后执行回调函数*/
  let sid = encodeURIComponent(srcPath)
  let oldScriptEle = document.getElementById(sid)

  if (!oldScriptEle) {
    let s = document.createElement("script")
    s.src = srcPath
    s.id = sid
    document.body.appendChild(s)

    s.onload = s.onreadystatechange = function (_, isAbort) {
      /* 借鉴自ace.js */
      if (isAbort || !s.readyState || s.readyState === "loaded" || s.readyState === "complete") {
        s = s.onload = s.onreadystatechange = null
        if (!isAbort) {
          callback()
        }
      }
    }
  }
}

export function traverseFieldWidgets(widgetList, handler, parent = null) {
  widgetList.forEach((w) => {
    if (w.formItemFlag) {
      handler(w, parent)
    } else if (w.type === "grid") {
      w.cols.forEach((col) => {
        traverseFieldWidgets(col.widgetList, handler, w)
      })
    } else if (w.type === "table") {
      w.rows.forEach((row) => {
        row.cols.forEach((cell) => {
          traverseFieldWidgets(cell.widgetList, handler, w)
        })
      })
    } else if (w.type === "tab") {
      w.tabs.forEach((tab) => {
        traverseFieldWidgets(tab.widgetList, handler, w)
      })
    } else if (w.type === "sub-form") {
      traverseFieldWidgets(w.widgetList, handler, w)
    } else if (w.category === "container") {
      // 自定义容器
      traverseFieldWidgets(w.widgetList, handler, w)
    }
  })
}

export function traverseContainWidgets(widgetList, handler) {
  widgetList.forEach((w) => {
    if (w.category === "container") {
      handler(w)
    }

    if (w.type === "grid") {
      w.cols.forEach((col) => {
        traverseContainWidgets(col.widgetList, handler)
      })
    } else if (w.type === "table") {
      w.rows.forEach((row) => {
        row.cols.forEach((cell) => {
          traverseContainWidgets(cell.widgetList, handler)
        })
      })
    } else if (w.type === "tab") {
      w.tabs.forEach((tab) => {
        traverseContainWidgets(tab.widgetList, handler)
      })
    } else if (w.type === "sub-form") {
      traverseContainWidgets(w.widgetList, handler)
    } else if (w.category === "container") {
      // 自定义容器
      traverseContainWidgets(w.widgetList, handler)
    }
  })
}

export function traverseAllWidgets(widgetList, handler) {
  widgetList.forEach((w) => {
    handler(w)

    if (w.type === "grid") {
      w.cols.forEach((col) => {
        handler(col)
        traverseAllWidgets(col.widgetList, handler)
      })
    } else if (w.type === "table") {
      w.rows.forEach((row) => {
        row.cols.forEach((cell) => {
          handler(cell)
          traverseAllWidgets(cell.widgetList, handler)
        })
      })
    } else if (w.type === "tab") {
      w.tabs.forEach((tab) => {
        traverseAllWidgets(tab.widgetList, handler)
      })
    } else if (w.type === "sub-form") {
      traverseAllWidgets(w.widgetList, handler)
    } else if (w.category === "container") {
      // 自定义容器
      traverseAllWidgets(w.widgetList, handler)
    }
  })
}

function handleWidgetForTraverse(widget, handler) {
  if (widget.category) {
    traverseFieldWidgetsOfContainer(widget, handler)
  } else if (widget.formItemFlag) {
    handler(widget)
  }
}

/**
 * 遍历容器内的字段组件
 * @param con
 * @param handler
 */
export function traverseFieldWidgetsOfContainer(con, handler) {
  if (con.type === "grid") {
    con.cols.forEach((col) => {
      col.widgetList.forEach((cw) => {
        handleWidgetForTraverse(cw, handler)
      })
    })
  } else if (con.type === "table") {
    con.rows.forEach((row) => {
      row.cols.forEach((cell) => {
        cell.widgetList.forEach((cw) => {
          handleWidgetForTraverse(cw, handler)
        })
      })
    })
  } else if (con.type === "tab") {
    con.tabs.forEach((tab) => {
      tab.widgetList.forEach((cw) => {
        handleWidgetForTraverse(cw, handler)
      })
    })
  } else if (con.type === "sub-form") {
    con.widgetList.forEach((cw) => {
      handleWidgetForTraverse(cw, handler)
    })
  } else if (con.category === "container") {
    // 自定义容器
    con.widgetList.forEach((cw) => {
      handleWidgetForTraverse(cw, handler)
    })
  }
}

/**
 * 获取所有字段组件
 * @param widgetList
 * @returns {[]}
 */
export function getAllFieldWidgets(widgetList) {
  let result = []
  let handlerFn = (w) => {
    result.push({
      type: w.type,
      name: w.options.name,
      field: w,
    })
  }
  traverseFieldWidgets(widgetList, handlerFn)

  return result
}

/**
 * 获取所有容器组件
 * @param widgetList
 * @returns {[]}
 */
export function getAllContainerWidgets(widgetList) {
  let result = []
  let handlerFn = (w) => {
    result.push({
      type: w.type,
      name: w.options.name,
      container: w,
    })
  }
  traverseContainWidgets(widgetList, handlerFn)

  return result
}

export function copyToClipboard(content, clickEvent, $message, successMsg, errorMsg) {
  const clipboard = new Clipboard(clickEvent.target, {
    text: () => content,
  })

  clipboard.on("success", () => {
    $message.success(successMsg)
    clipboard.destroy()
  })

  clipboard.on("error", () => {
    $message.error(errorMsg)
    clipboard.destroy()
  })

  clipboard.onClick(clickEvent)
}

export function getQueryParam(variable) {
  let query = window.location.search.substring(1)
  let vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    if (pair[0] == variable) {
      return pair[1]
    }
  }

  return undefined
}

export function getDefaultFormConfig() {
  return {
    modelName: "formData",
    refName: "vForm",
    rulesName: "rules",
    labelWidth: 80,
    labelPosition: "top",
    size: "small",
    labelAlign: "label-left-align",
    cssCode: "",
    customClass: "",
    functions: "", // 全局函数
    layoutType: "PC",
    dataSources: [], // 数据源集合
    disabled: false,

    onFormCreated: "",
    onFormMounted: "",
    onFormDataChange: "",
  }
}

export function buildDefaultFormJson() {
  return {
    widgetList: [],
    formConfig: deepClone(getDefaultFormConfig()),
  }
}

/**
 * 转译选择项数据
 * @param rawData
 * @param widgetType
 * @param labelKey
 * @param valueKey
 * @returns {[]}
 */
export function translateOptionItems(rawData, widgetType, labelKey, valueKey, auxiliaryTextKey) {
  if (widgetType === "cascader") {
    // 级联选择不转译
    return deepClone(rawData)
  }
  let result = []
  if (!!rawData && rawData.length > 0) {
    rawData.forEach((ri) => {
      result.push({
        label: ri[labelKey],
        value: ri[valueKey],
        widgetNames: ri.widgetNames,
        auxiliaryText: ri[auxiliaryTextKey], // 下拉选项辅助文字
      })
    })
  }

  return result
}

/**
 * 组装axios请求配置参数
 * @param arrayObj
 * @param DSV
 * @returns {{}}
 */
export function assembleAxiosConfig(arrayObj) {
  let result = {}
  if (!arrayObj || arrayObj.length <= 0) {
    return result
  }

  arrayObj.map((ai) => {
    if (ai.type === "String") {
      result[ai.name] = String(ai.value)
    } else if (ai.type === "Number") {
      result[ai.name] = Number(ai.value)
    } else if (ai.type === "Boolean") {
      if (ai.value.toLowerCase() === "false" || ai.value === "0") {
        result[ai.name] = false
      } else if (ai.value.toLowerCase() === "true" || ai.value === "1") {
        result[ai.name] = true
      } else {
        result[ai.name] = null
      }
    } else if (ai.type === "Variable") {
      result[ai.name] = eval(ai.value)
    }
  })

  return result
}
function addToken(config) {
  const token = window.abp.auth.getToken()
  if (token) config.headers.Authorization = "Bearer " + window.abp.auth.getToken()
}

function buildRequestConfig(dataSource, DSV, isSandbox) {
  let config = {}
  if (dataSource.requestURLType === "String") {
    config.url = dataSource.requestURL
  } else {
    config.url = eval(dataSource.requestURL)
  }
  config.method = dataSource.requestMethod

  config.headers = assembleAxiosConfig(dataSource.headers, DSV)
  addToken(config)
  config.params = assembleAxiosConfig(dataSource.params, DSV)
  config.data = assembleAxiosConfig(dataSource.data, DSV)

  // let chFn = new Function('config', 'sandbox', 'form', 'widget', dataSource.configHandlerCode)
  let chFn = new Function("config", "isSandbox", "DSV", dataSource.configHandlerCode)
  return chFn.call(null, config, isSandbox, DSV)
}

export async function runDataSourceRequest(dataSource, DSV, isSandbox, $message) {
  try {
    let requestConfig = buildRequestConfig(dataSource, DSV, isSandbox)
    // console.log('test------', requestConfig)
    let result = await axios.request(requestConfig)

    // let dhFn = new Function('result', 'sandbox', 'form', 'widget', dataSource.dataHandlerCode)
    let dhFn = new Function("result", "isSandbox", "DSV", dataSource.dataHandlerCode)
    return dhFn.call(null, result, isSandbox, DSV)
  } catch (err) {
    // let ehFn = new Function('error', 'sandbox', 'form', 'widget', '$message', dataSource.dataHandlerCode)
    let ehFn = new Function("error", "isSandbox", "DSV", "$message", dataSource.errorHandlerCode)
    ehFn.call(null, err, isSandbox, DSV, $message)
    console.error(err)
  }
}

export function getDSByName(formConfig, dsName) {
  let resultDS = null
  if (!!dsName && !!formConfig.dataSources) {
    formConfig.dataSources.forEach((ds) => {
      if (ds.uniqueName === dsName) {
        resultDS = ds
      }
    })
  }

  return resultDS
}
/**
 * 转为树形数据
 * @param {Array} res 待转的数据
 * @param {String} key 排序关键字
 * @returns Array
 */
export function translateDataToTree(res, key) {
  key = key || "id"
  let parents = res.filter((item) => !item.parentId)
  const childrens = res.filter((item) => item.parentId)
  parents.sort((a, b) => {
    return a.sort - b.sort
  })
  const translator = (parents, childrens) => {
    parents.forEach((parent) => {
      childrens.forEach((current, index) => {
        if (current.parentId === parent[key]) {
          const temp = JSON.parse(JSON.stringify(childrens))
          temp.splice(index, 1)
          translator([current], temp)
          parent.children ? parent.children.push(current) : (parent.children = [current])
        }
      })
    })
  }
  // 调用转换方法
  translator(parents, childrens)
  return parents
}

export function handleTableData(res, allusers, columns) {
  res.data.map((item, i) => {
    const obj = JSON.parse(item)
    obj.rawData = { ...obj }
    obj.raw = {}
    Object.keys(obj).map((field) => {
      if (field === "rawData") return
      if (field.includes("subform")) {
        const subFormRow = []
        const subFormObj = []
        obj[field].value = JSON.parse(obj[field].value)
        obj[field].value.map((row, i) => {
          // row 行数据
          subFormRow[i] = {}
          subFormObj[i] = {}
          row.map((col) => {
            // col 列字段数据
            if (col.FieldId.includes("upload")) {
              subFormRow[i][col.FieldId] = col.RawValue
              subFormObj[i][col.FieldId] = col.FieldId.includes("fileupload") ? col.RawValue : col.Value
            } else {
              subFormRow[i][col.FieldId] = col.FieldId.includes("cascader") ? JSON.parse(col.Value) : col.Value
              if (Array.isArray(col.RawValue) && col.RawValue.length) {
                let rawLabels = col.RawValue.map((raw) => raw.label)
                if (col.FieldId.includes("cascader") && col.RawValue.length) {
                  col.Value = JSON.parse(col.Value)
                  if (col.Value.length && Array.isArray(col.Value[0])) {
                    // 多选
                    rawLabels = []
                    col.Value.map((v) => {
                      col.RawValue.some((r) => {
                        if (r.value === v[v.length - 1]) {
                          rawLabels.push(r.label)
                          return true
                        }
                      })
                    })
                  } else {
                    // 级联单选只拿最后一条数据的 label
                    rawLabels = [col.RawValue[col.RawValue.length - 1].label]
                  }
                }
                subFormObj[i][col.FieldId] = rawLabels.join(", ")
              } else {
                subFormObj[i][col.FieldId] = Array.isArray(col.Value) ? "" : col.Value
              }
            }
          })
        })
        obj.raw[field] = subFormRow
        obj[field] = subFormObj
        return
      }
      if (columns) {
        let f = columns.filter((e) => {
          return e.id == field
        })
        structureData(obj, field, f[0])
      } else {
        structureData(obj, field)
      }
    })

    const objCopy = JSON.parse(item)
    var key = null
    // 登辉模具添加部门
    if (objCopy.user81210) {
      key = "user81210"
    }
    if (objCopy.user58695) {
      key = "user58695"
    }
    if (key && objCopy[key] && objCopy[key].rawValue) {
      var ids = objCopy[key].rawValue.map((e) => {
        return Number(e.value)
      })
      var organizations = allusers
        .filter((e) => {
          return ids.includes(e.id)
        })
        .map((ele) => {
          return ele.organizationName
        })
        .join(",")

      Vue.set(obj, "organization", organizations)
    }
    // 青山青数据更改重新映射
    if (objCopy.date8517 && objCopy.timerange62788) {
      if (!objCopy.date40840 && !objCopy.date50660) {
        var day = dayjs(objCopy.date8517.value.$date).format("YYYY-MM-DD")
        var times = objCopy.timerange62788.value
        Vue.set(obj, "date40840", day + " " + times[0])
        Vue.set(obj, "date50660", day + " " + times[1])
      }
    }
    res.data[i] = obj
  })
  return res
}

function structureData(obj, field, col) {
  if (field.includes("cascader")) obj[field].value = JSON.parse(obj[field].value)
  if (Array.isArray(obj[field]?.rawValue) && obj[field].rawValue.length && !field.includes("upload")) {
    let labels = obj[field].rawValue.map((opt) => opt.label)
    const len = obj[field].rawValue.length
    if (field.includes("cascader") && len) {
      if (obj[field].value.length && Array.isArray(obj[field].value[0])) {
        // 多选
        labels = []
        obj[field].value.map((v) => {
          obj[field].rawValue.some((r) => {
            if (r.value === v[v.length - 1]) {
              labels.push(r.label)
              return true
            }
          })
        })
      } else {
        // 级联单选只拿最后一条数据的 label
        labels = [obj[field].rawValue[len - 1].label]
      }
    }
    if (obj[field].props) {
      if (obj[field].props.mlmwtype === "mould") {
        obj.raw[field] = obj[field].value
      } else if (obj[field].props.enableMultiSelect) {
        obj.raw[field] = obj[field].value.map((raw) => +raw)
      } else {
        if (obj[field].value?.length) obj.raw[field] = +obj[field].value[0]
      }
    } else {
      obj.raw[field] = obj[field].value
    }
    obj[field] = labels.join(", ") || obj[field].value
  } else if (typeof obj[field]?.value === "boolean") {
    obj.raw[field] = obj[field].value
    obj[field] = obj[field].value ? "是" : "否"
  } else if (Array.isArray(obj[field]?.value)) {
    if (field.includes("upload")) {
      obj.raw[field] = obj[field].rawValue
      obj[field] = field.includes("fileupload") ? obj[field].rawValue : obj[field].value[0] ? obj[field].value : []
    } else {
      obj.raw[field] = obj[field].value
      obj[field] = obj[field].value.join("至")
    }
  } else if (obj[field]?.value && typeof obj[field]?.value === "object") {
    if (obj[field].value.$date) {
      let format = "YYYY-MM-DD"
      if (col) {
        format = col.options.format
        format = format.replace(/dd/gi, "DD")
        format = format.replace(/yyyy/gi, "YYYY")
      }
      obj[field] = dayjs(obj[field].value.$date).format(format)
    } else {
      obj[field] = obj[field].value.$numberDecimal || +obj[field].value.$numberInt
    }
    obj.raw[field] = obj[field]
  } else if (field !== "raw") {
    const opeMapping = [
      "creatoruserid",
      "lastmodifieruserid",
      "creatorname",
      "lastmodifiername",
      "creationtime",
      "lastmodificationtime",
      "sn",
    ]
    if (opeMapping.includes(field)) {
      obj[field] = !["creationtime", "lastmodificationtime"].includes(field)
        ? obj[field]
        : dayjs(obj[field].$date).format("YYYY-MM-DD HH:mm:ss")
    } else {
      obj[field] = obj[field].value || obj[field].$oid
      obj.raw[field] = obj[field]
    }
  }
}

/**
 * @description: 表单数据集数据保存处理
 * @param {*} dom form render ref dom
 * @param {*} activeRowData 单选复选未 change 时保存 rowValue 将丢失，只能从原数据中拿到 rowValue
 * @return {*}
 */
export async function handleFormData(dom, msg, activeRowData = null) {
  const widgets = dom.getFieldWidgets()
  const containers = dom.getContainerWidgets()

  const subFormValueLabels = {}
  containers.map((c) => {
    if (c.type === "sub-form") {
      c.container.widgetList.some((item) => {
        if (!subFormValueLabels[item.id])
          subFormValueLabels[item.id] = { type: "", label: "", valueLabels: [], Props: {} }
        if (["radio", "checkbox", "select", "multiselect", "user", "org", "cascader"].includes(item.type)) {
          const subForm = dom.getWidgetRef(c.name)
          subForm.rowIdData.some((rid, i) => {
            const subWidget = subForm.getWidgetRefOfSubForm(item.id, i)
            subFormValueLabels[item.id].type = subWidget.field.type
            subFormValueLabels[item.id].label = subWidget.field.options.label
            console.log(activeRowData?.rawData[c.name].value)
            const valueLabelItem = activeRowData?.rawData[c.name].value[i]?.find(
              (rowWidget) => rowWidget.FieldId === item.id
            )
            const valueLabel = subWidget.field.options.valueLabel || valueLabelItem?.RawValue
            subFormValueLabels[item.id].valueLabels[i] = valueLabel
            if (item.as === "mould") {
              subFormValueLabels[item.id].Props.mlmwtype = item.as
            }
            if (["user", "org"].includes(item.type)) {
              subFormValueLabels[item.id].Props.nableMultiSelect = subWidget.field.options.multiple
            }
          })
        } else {
          subFormValueLabels[item.id].type = item.type
          subFormValueLabels[item.id].label = item.options.label
        }
      })
    }
  })
  let formData
  try {
    formData = await dom.getFormData()
  } catch (error) {
    msg.error(error)
  }
  const res = []
  // 待优化
  Object.keys(formData).map((key) => {
    widgets.some((widget) => {
      if (widget.name === key) {
        const obj = {
          FieldType: widget.type,
          FieldId: key,
          Label: widget.field.options.label,
          Value: handleArrSort(formData[key], widget.type),
          RawValue: key.includes("upload")
            ? formData[key]?.map((file) => {
                return { name: file.name, url: file.url }
              })
            : widget.field.options.valueLabel || activeRowData?.rawData[key]?.rawValue,
        }
        if (widget.field.as === "mould") obj.Props = { mlmwtype: widget.field.as }
        if (["user", "org"].includes(widget.type)) {
          obj.Props = { enableMultiSelect: widget.field.options.multiple }
        }
        res.push(obj)
        return true
      }
    })
    const subFormData = []
    if (key.includes("subform")) {
      formData[key].map((f, i) => {
        subFormData[i] = []
        Object.keys(f).map((k) => {
          subFormData[i].push({
            FieldType: subFormValueLabels[k].type,
            FieldId: k,
            Label: subFormValueLabels[k].label,
            Value: handleArrSort(f[k], subFormValueLabels[k].type),
            RawValue: k.includes("upload")
              ? f[k]
                ? f[k].map((file) => {
                    return { name: file.name, url: file.url }
                  })
                : []
              : subFormValueLabels[k].valueLabels[i] || "",
            Props: subFormValueLabels[k].Props,
          })
        })
      })
    }
    containers.some((c) => {
      if (c.name === key) {
        res.push({
          FieldType: c.type,
          FieldId: key,
          Label: c.container.label,
          Value: JSON.stringify(subFormData),
          RawValue: "",
        })
        return true
      }
    })
  })
  return res
}

function handleArrSort(val, type) {
  if (Array.isArray(val) && type !== "cascader") {
    if (type.includes("upload")) {
      return val.map((item) => item.url)
    } else {
      return val.sort((a, b) => a - b)
    }
  }
  if (type === "cascader") return JSON.stringify(val)
  return val
}

//  判断是否为移动端
export function isMobile() {
  let isMobile = false
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    // console.log('移动端');
    isMobile = true
  }
  if (document.body.clientWidth < 800) {
    isMobile = true
  }
  return isMobile
}

/**
 * 防抖
 * @param {function} method 要运行的函数
 * @param {number} wait 等待时间 ms
 * @param {boolean } immediate 立即
 * @returns {function}
 */
export function debounce(method, wait = 300, immediate = false) {
  let timeout, result
  let debounced = function (...args) {
    var isPromise = (a) => a instanceof Promise
    // 返回一个Promise，以便可以使用then或者Async/Await语法拿到原函数返回值
    return new Promise((resolve) => {
      let context = this
      if (timeout) {
        clearTimeout(timeout)
      }
      if (immediate) {
        let callNow = !timeout
        timeout = setTimeout(() => {
          timeout = null
        }, wait)
        if (callNow) {
          result = method.apply(context, args)
          // 将原函数的返回值传给resolve
          if (isPromise(result)) {
            result.then((res) => resolve(res))
          } else {
            resolve(result)
          }
        }
      } else {
        timeout = setTimeout(() => {
          result = method.apply(context, args)
          // 将原函数的返回值传给resolve
          if (isPromise(result)) {
            result.then((res) => resolve(res))
          } else {
            resolve(result)
          }
        }, wait)
      }
    })
  }

  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export function chartsResize(charts) {
  Object.values(charts).map((v) => {
    v.resize({ animation: { duration: 800 } })
  })
}

/**
 * @description: 导出excel
 * @param {*} columns 表头
 * @param {*} data 数据
 * @param {*} title 导出标题
 */
export async function export2Excel(columns, data, title) {
  const COLWIDTH = 12
  const hasSubform = columns.find((col) => col.type === "sub-form") // 是否拥有子表单
  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet(title)
  const sheetColumns = [...columns]
  const rows = [...data]
  const mergeCols = [] // 需要向下合并的单元格列
  const mergeRows = [] // 需要向下合并的单元格行数
  const subformColLens = []
  if (hasSubform) {
    const subformIds = [] // 子表单 id
    let colIndex = 0
    rows.length = 0
    sheetColumns.length = 0
    // 子表单处理
    columns.map((col) => {
      if (col.type !== "sub-form") {
        colIndex++
        sheetColumns.push(col)
        mergeCols.push(colIndex)
      } else {
        subformIds.push(col.id)
        subformColLens.push({ start: colIndex, end: colIndex })
        col.widgetList.map((children) => {
          colIndex++
          subformColLens[subformColLens.length - 1].end++
          sheetColumns.push({ ...children, isShow: true, isSubform: true, subformLabel: col.label })
        })
      }
    })
    let dataIndex = 0
    data.map((row) => {
      let rowLen = 0
      dataIndex = rows.length
      let startRowIndex = []
      let endRowIndex = []
      subformIds.map((id, j) => {
        startRowIndex[j] = 0
        endRowIndex[j] = 0
        if (j) dataIndex = dataIndex - rowLen
        if (!row[id] || !row[id].length) row[id] = []
        row[id].map((subformRow, index) => {
          if (!index) {
            rows[dataIndex] = { ...rows[dataIndex], ...row, ...subformRow }
            // rows.push({ ...row, ...subformRow })
            startRowIndex[j] = rows.length
          } else {
            rows[dataIndex] = { ...rows[dataIndex], ...subformRow }
            // rows.push({ ...subformRow })
            endRowIndex[j] = rows.length
          }
          dataIndex++
        })
        rowLen = row[id].length
      })
      if (Math.max(...endRowIndex)) mergeRows.push([Math.min(...startRowIndex) + 1, Math.max(...endRowIndex) + 1])
    })
  }
  sheet.columns = sheetColumns.map((col) => ({
    header: col.label || col.options.label,
    key: col.id,
    hidden: !col.isShow,
    width: !col.width || col.width === "auto" ? COLWIDTH : col.width / 8,
  }))

  const resultRows = []
  rows.map((row) => {
    const obj = {}
    sheetColumns.map((col) => {
      if (col.type === "picture-upload") {
        if (row[col.id]?.length) {
          // FIXME: 图片转 base64
          // row[col.id].map((imgUrl) => {
          //   const base64Img = imgUrl
          //   const imageId = workbook.addImage({
          //     base64: base64Img,
          //     extension: "png",
          //   })
          //   sheet.addImage(imageId, {
          //     tl: { col: colIndex, row: rowIndex + 1 },
          //     ext: { width: 50, height: 30 },
          //   })
          // })
        } else {
          obj[col.id] = ""
        }
      } else if (col.type === "file-upload") {
        if (row[col.id]) {
          const files = row[col.id].map((item) => item.url)
          // const files = row[col.id].map((item) => ({
          //   text: item.name,
          //   hyperlink: item.url,
          //   tooltip: item.name,
          // }))
          obj[col.id] = files.join(",")
        } else {
          obj[col.id] = ""
        }
      } else {
        obj[col.id] = row[col.id] || ""
      }
    })
    resultRows.push(obj)
  })
  if (hasSubform) {
    // sheet.addRow({})
    const firstRow = {}
    sheetColumns.map((item) => {
      firstRow[item.id] = item.isSubform ? item.subformLabel : item.label
    })
    sheet.insertRow(1, firstRow)
    sheet.addRows(resultRows)
    subformColLens.map((item) => {
      sheet.mergeCells(1, item.start + 1, 1, item.end)
    })
    mergeCols.map((i) => {
      sheet.mergeCells(1, i, 2, i)
    })
  } else {
    sheet.addRows(resultRows)
  }
  mergeCols.map((i) => {
    mergeRows.map((row) => {
      sheet.mergeCells(row[0] + 1, i, row[1] + 1, i)
    })
  })
  sheet.eachRow((row, index) => {
    if (index < (hasSubform ? 3 : 2)) {
      row.fill = {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: "c0c0c0" },
      }
      row.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }
    }
    row.height = 30
    row.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    }
  })
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  })
  FileSaver.saveAs(blob, title + ".xlsx")
}
